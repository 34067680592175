.features-section {
    .section-title {
        h2 {
            max-width: inherit;
        }

        p {
            max-width: 800px;
        }
    }
}
@import'../../sass//colors.scss';

.icon-list {
    display: flex;
    align-items: center;
    margin-bottom: 70px;

    .icon-list-text {
        margin-left: 30px;
        max-width: 300px;

        h3 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        p {
            font-size: 15px;
            margin-bottom: 0;
            font-weight: 500;
        }
    }
}

// Mobile Responsive
@media (max-width:575px){
    .icon-area {
        .icon-list {
            margin-bottom: 25px;
        }
    }
}